.hero {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  height: 100vh;
}
.left-h {
  padding: 4rem;
  padding-top: 2.5rem;
  flex: 1 1;
  display: flex;
  gap: 2rem;
  flex-direction: column;
}
.right-h {
  flex: 1 1;
  flex-direction: column;
  position: relative;
  background-color: black;
  padding: 4rem;
  padding-top: 2.5rem;
}
.reliable-add {
  display: flex;
  padding-top: 6rem;
  flex-direction: column;
  font-size: 4.5rem;
  color: var(--coffee);
  text-overflow: inherit;
  font-family: monospace;
}
.left-h > hr {
  border: 1px solid var(--coffee);
  width: 100%;
}
.first.div > span {
  font-size: 2rem;
}
.first-div > span:nth-child(2) {
  color: var(--orange);
}

.our-work {
  display: flex;
  flex-direction: column;
  padding-top: 3rem;
}
.our-work > span {
  font-size: 1.5rem;
}
.hero-buttons {
  display: flex;
  gap: 1rem;
  font-weight: normal;
  align-items: center;
}
.hero-buttons > :nth-child(1) {
  color: white;
  background-color: var(--coffee);
  width: 8rem;
  border-radius: 25px;
}

.hero-buttons > :nth-child(2) {
  color: var(--coffee);
  background-color: transparent;
  width: 12rem;
  border: 2px solid var(--coffee);
  border-radius: 25px;
  gap: 2rem;
}
.hero-buttons .btn:last-child {
  color: black;
  font-weight: bold;
  font-size: 1.5rem;
}
.hero-buttons a {
  color: white;
}
.btn > img {
  filter: sepia(100%);
  width: 2rem;
  padding: 1rem;
}
.boost-build {
  display: flex;
  gap: 5rem;
  padding-top: 7rem;
  font-size: 1rem;
}
.boost-build > :nth-child(1) {
  width: 15rem;
}
.boost-build > :nth-child(2) {
  width: 15rem;
}
.chip {
  display: flex;
  flex-direction: column;
}
.chip > img {
  padding-bottom: 1rem;
  width: 3rem;
}
.microchip {
  display: flex;
  flex-direction: column;
}
.microchip > img {
  padding-bottom: 1rem;
  width: 3rem;
}
.right-button {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.socials {
  display: flex;
  position: absolute;
  left: 50rem;
  gap: 2rem;
  color: white;
  margin: 0 1rem;
}
a.socials:hover {
  transform: translateY(-2px);
}
a.socials {
  margin: 0 1rem;
  transition: transform 250ms;
  display: inline-block;
}
a.linkedin {
  color: white;
}

a.behance {
  color: white;
}

a.twitter {
  color: white;
}

a.github {
  color: white;
}

.dpic > img {
  position: absolute;
  width: 35rem;
  height: 50rem;
  object-fit: cover;
  right: 12rem;
  top: 2rem;
}
.dpic {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: fit-content;
  padding: 1rem;
  align-items: flex-start;
  border-radius: 5px;
  position: absolute;
  right: 5rem;

  top: 15rem;
}
.dpic > :nth-child(1) {
  position: absolute;
  width: 35rem;
  height: 45rem;
  border: 2px solid orange;
  background-color: transparent;
  right: 15rem;
  top: 0.9rem;
}
.dpic > :nth-child(2) {
  position: absolute;
  width: 35rem;
  height: 45rem;
  right: 7rem;
  top: 4rem;
  background: var(--planCard);
}

.arrow-down > img {
  position: absolute;
  width: 5rem;
}

.arrows {
  display: flex;
  flex-direction: column;
  position: relative;
  top: 50rem;
  padding: 1px;
  left: 1rem;
  background-color: none;
  border: 2px solid orange;
  border-radius: 10px;
  align-items: center;
}
.arrows > span {
  color: yellow;
  padding-left: 0.5rem;
  font-size: 2rem;
  padding: 1px;
}

.arrows > img {
  width: 0rem;
  cursor: pointer;
  fill: orange;
}
.btn {
  outline: none;
  background-color: transparent;
  color: white;
  align-items: center;
  height: 2rem;
  text-align: center;
  text-transform: uppercase;
  font-family: monospace;
  font-weight: lighter;
}

@media screen and (max-width: 768px) {
  .hero {
    flex-direction: column;
    overflow: scroll;
  }

  .first-div {
    display: flex;
    text-align: center;
    justify-content: center;
  }
  .hero-blur {
    width: 14rem;
  }

  .our-work {
    margin-top: 0;
    font-size: small;
    align-self: center;
    transform: scale(0.8);
  }

  .reliable-add {
    font-size: large;
    display: flex;
    font-size: 3rem;
    text-align: center;
    justify-content: center;
  }

  .hero-buttons {
    justify-content: center;
  }
  .boost-build {
    margin-top: 0;
    font-size: large;
    align-self: center;
    transform: scale(0.8);
  }
  .right-h {
    position: relative;
    flex-direction: column;
    background: none;
    bottom: 20rem;
  }

  .dpic > :nth-child(1) {
    position: relative;
    display: none;
    width: 25rem;
    height: 35rem;
  }
  .dpic > :nth-child(2) {
    display: block;
    bottom: 0;
    left: 0;
    width: 25rem;
    height: 35rem;
  }
  .dpic > img {
    position: relative;
    top: 1rem;
    right: 0;
    width: 25rem;
    height: 35rem;
    padding-bottom: 2rem;

    align-self: center;
  }
  .socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    left: 1rem;
    top: 30rem;
    padding: 20px;
  }
  .arrows {
    position: absolute;
    right: 50px;
    overflow: hidden;
  }

  .arrows {
    background-color: black;
    border: none;
    border-radius: 50rem;
    width: 15rem;
  }
  a.socials:hover {
    transform: translateY(-2px);
  }
  a.socials {
    margin: 0 1rem;
    transition: transform 250ms;
    display: inline-block;
  }
  a.linkedin {
    color: black;
  }

  a.behance {
    color: black;
  }

  a.twitter {
    color: black;
  }

  a.github {
    color: black;
  }
  .Line {
    color: black;
  }
  .first-div > span:nth-child(2) {
    padding: 0 1rem;
  }
}
